<template>
<v-app>
    <v-row class="pa-12 mt-12">
        <h2 class="secondary--text">جدول العيادات الاستشارية</h2>
        <v-spacer />
        <h2 class="primary--text">{{nextDayNameInArabic}}</h2>
        <h2 class="mx-2"> - </h2>
        <h2 dir="ltr" class="secondary--text"> {{ nextDay }} </h2>
        <v-btn outlined @click="$router.go(-1)" class="mr-12 btn">
            <h4>رجوع</h4>
            <v-icon>
                fi fi-rr-arrow-small-left
            </v-icon>
        </v-btn>
    </v-row>
    <!-- <v-toolbar flat color="primary" height="100px" class="white--text text-center">
        <table class="white--text text-center">
            <tr class="mx-auto">
                <th>
                    <h4>
                        العيادات الاستشارية
                    </h4>
                </th>
                <th>
                    <h4>
                        من 8:00 صباحاً
                        حتى 2:30 ظهراً
                    </h4>
                </th>

                <th>
                    <h4>
                        من 2:30 ظهراً
                        حتى 9:00 مساءً
                    </h4>
                </th>
            </tr>
        </table>
    </v-toolbar> -->
    <loading v-if="loading" />
    <table class="secondary--text " v-if="!loading">
        <thead class="primary " style="height: 100px;">
            <th >
                <h4>
                    العيادات الاستشارية
                </h4>
            </th>
            <th>
                <h4>
                    من 8:00 صباحاً
                    حتى 2:30 ظهراً
                </h4>
            </th>
            <th>
                <h4>
                    من 2:30 ظهراً
                    حتى 9:00 مساءً
                </h4>
            </th>
        </thead>
        <tbody>
            <tr v-for="(item,index) in items" :key="index">
                <td class="light-green lighten-3" >
                    <a :href="'clinic/'+item.id">
                        <h4>
                            {{ item.name }}
                        </h4>
                    </a>
                </td>
                <td class="light-green lighten-3" >
                    <h3 v-for="(dr,i) in item.doctors" :key="i">
                        <span v-if="dr.isMorning == true">{{ dr.fullName }}</span>
                    </h3>
                    <h3 v-if="item.doctors.length == 0 || (item.doctors.map(x => x.isMorning == true).length == 0)">....</h3>
                </td>

                <td class="light-green lighten-3" >
                    <h3 v-for="(dr,i) in item.doctors" :key="i">
                        <span v-if="dr.isMorning == false">{{ dr.fullName }}</span>
                    </h3>
                    <h3 v-if="item.doctors.length == 0 || (item.doctors.map(x => x.isMorning == false).length == 0)">....</h3>
                </td>
            </tr>
        </tbody>
    </table>
    <v-footer>
        <v-avatar tile size='150' class="mt-1 mx-12">
            <v-img contain src="@/assets/images/logo.png"></v-img>
        </v-avatar>
        <v-spacer />
        <div class="mx-12">
            <!-- <h3>07744700012</h3>
            <h3>07744700013</h3> -->
        </div>
    </v-footer>
</v-app>
</template>

<style scoped>
table {
    width: 100%;
    
}


td,
th {
    text-align: center;
    padding: 14px;
    font-size: 1rem;
}

th{
    color: white;
}
.secondary--text {
    border-collapse: separate;
    border-spacing: 10 10px;
}

</style>

<script>
import loading from '../../components/Loading.vue';
export default {
    components: {
        loading
    },

    data() {
        return {
            nextDay: '',
            nextDayNameInArabic: '',
            searchName: '',
            items: [],
            loading: false
        }
    },

    created() {
        this.getItems()

        let today = new Date();
        // today.setDate(today.getDate() + 1);
        today.setDate(today.getDate());
        let arabicDayNameFormatter = new Intl.DateTimeFormat('ar-EG', {
            weekday: 'long'
        });
        let nextDayNameInArabic = arabicDayNameFormatter.format(today);
        this.nextDayNameInArabic = nextDayNameInArabic;
        let nextDay = today.getFullYear() + '/' + (today.getMonth()+1) + '/' + today.getDate();
        // let nextDay = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
        console.log(nextDay);
        this.nextDay = nextDay;

    },

    methods: {

        makePhoneCall() {
            window.open("tel:5055");
        },

        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Clinic?PageIndex=1&PageSize=1000&Name=${this.searchName}`)
                this.items = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
